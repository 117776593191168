const { REACT_APP_GCP_ENV_PREFIX = "" } = process.env;

export const METHODS = {
  GET: "get",
  POST: "post",
};

export const INTRODUCTION = "introduction";

export const PAGES = {
  AUTH: "auth",
  SHIPPING: "shipping",
  // RETURNS: "returns",
  // PICKUP: "pickup",
  // TRACKING: "tracking",
};

export const SECURITY = {
  BASIC_AUTH: "basicAuth",
  BEARER_TOKEN: "bearerAccessToken",
  BEARER_REFRESH: "bearerRefreshToken",
};

export const APPLICATION_TYPE = {
  JSON: "application/json",
  HTML: "text/html",
  ELTRON: "text/vnd.eltron-epl",
  CITIZEN: "text/vnd.citizen-clp",
  ZEBRA: "text/vnd.zebra-zlp",
};

export const HTTP_STATUS = {
  OK: "200",
};

export const HTTP_METHODS = {
  GET: "get",
  POST: "post",
};

export const DEFAULT_CONTENT_TYPES = [APPLICATION_TYPE.JSON];

export const REGEX = {
  CAMELCASE: /[-_\s]+(.)?/g,
  BRACKETS: /\(|\)/g,
  NUMBERS: /[0-9]/g,
  VERSION: /^v\d+$/,
};

export const PRINTER_TYPES = [
  APPLICATION_TYPE.CITIZEN,
  APPLICATION_TYPE.ELTRON,
  APPLICATION_TYPE.ZEBRA,
  APPLICATION_TYPE.HTML,
];

export const PATHS = {
  GETTING_STARTED: "/getting-started",
  REGISTRATION: "/getting-started/registration",
  INTRODUCTION: "/introduction",
  AUTH_INTRODUCTION: "/auth/introduction",
  CUSTOMER_PORTAL: "customer-portal",
  EXTERNAL: {
    MY_ADMIN: `https://${REACT_APP_GCP_ENV_PREFIX}myadmin.dpd.co.uk`,
    UK_DELIVERY:
      "https://www.dpd.co.uk/content/products_services/uk_delivery_services.jsp",
    INTERNATIONAL_DELIVERY: "https://international.dpd.co.uk",
  },
};

export const HEADERS = {
  "CONTENT-TYPE": "Content-Type",
  ACCEPT: "Accept",
  AUTHORIZATION: "Authorization",
};

export const HEADER_PARAMETER_NAMES = {
  [HEADERS["CONTENT-TYPE"]]: "content-type",
  [HEADERS.ACCEPT]: "accept",
};

export const FILE_TYPES = {
  JSON: "json",
  YAML: "yaml",
};
